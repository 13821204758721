/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Samsung Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Samsung Sans Regular"),
    url("SamsungSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Samsung Sans Thin"), url("SamsungSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Samsung Sans Light"), url("SamsungSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sans Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Samsung Sans"), url("SamsungSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Samsung Sans Bold"), url("SamsungSans-Bold.woff") format("woff");
}
